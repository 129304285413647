<template>
  <div>
    <div :id="divid" style="width: 300px; height:300px"></div>
  </div>
</template>
<script>
export default {
  name: 'Proechart',
  props: ['divid'],
  data () {
    return {
      data: []
    }
  },
  methods: {
    drawChart () {
      this.data = [
        { value: 335, name: '民事' },
        { value: 310, name: '行政' },
        { value: 135, name: '刑事' },
        { value: 234, name: '执行' }
      ]
      this.init()
    },
    init () {
      var myChart = this.$echarts.init(document.getElementById(this.divid))
      var option = {
        color: ['#38a2fc', '#4ecb7a', '#985fe2', '#fad338', '#35c9ca'],
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        legend: {
          bottom: 10,
          data: ['民事', '行政', '刑事', '执行']
        },
        series: [
          {
            name: '办件',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '30',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: this.data
          }
        ]
      }
      myChart.setOption(option)
    }
  },
  mounted () {
    this.drawChart()
  }
}
</script>
<style>
</style>

<template>
  <Page title="人员详情">
    <div class="gray-bgnew">
      <div class="list-wrap wrapper">
        <div class="location">
          当前位置：
          <a href="/">首页</a>
          <span>&gt;</span>
          <a href="#">法治地图</a>
          <span>&gt;</span>
          <a href="#">主体详情</a>&gt;
        </div>
        <div class="framework">
          <div class="side-catalog-wrap">
          <a-anchor :target-offset="targetOffset">
            <a-anchor-link href="#info01" title="执业信息" />
            <a-anchor-link href="#info02" title="专业能力" />
            <a-anchor-link href="#info03" title="服务业绩" />
            <a-anchor-link href="#info04" title="服务记录" />
            <a-anchor-link href="#info05" title="信用记录" />
          </a-anchor>
          </div>
          <div class="staff corner">
            <div class="framework-corner"></div>
            <div class="staff-header">
              <h4 class="title">服务队伍信息</h4>
              <div class="time">律师联系电话: <span>暂未公开</span></div>
            </div>
            <div class="staff-body clearfix">
              <div class="lawwer-staff-wrap">
                <div
                  class="pic"
                  style="
                    background-image: url(http://sh.12348.gov.cn/service/rest/tk.File/2ffc121d9344432794fa88c28fa87d69/view?thumb=120x120);
                  "
                ></div>
                <div class="content-box">
                  <div class="pull-left sm">
                    <span class="tit">执业年限：</span>
                    <span class="cont tex-name">
                      <i class="color-number">10</i>年执业经验
                    </span>
                  </div>
                  <div class="pull-left">
                    <span class="tit">业务专长：</span>
                    <span class="cont">
                      <b class="b-tag">未填写专业特长</b>
                    </span>
                    <small
                      class="tab-section-prompt left"
                      title="该信息由信用主体自行填写"
                    >
                      <i class="iconfont icon-mind"></i>
                    </small>
                  </div>
                  <div class="pull-left sm">
                    <span class="tit">执业机构：</span>
                    <span class="cont tex-name"
                      ><a
                        target="blank"
                        href="cim.LawFirm.jsp?subjectId=6d48422a51eb45fd9098a1a9b00730e5"
                        >上海浩锦律师事务所</a
                      ></span
                    >
                  </div>
                  <div class="pull-left">
                    <span class="tit">执业证号：</span>
                    <span class="cont">13101200910651500</span>
                  </div>
                </div>

                <div class="cont-number-box">
                  <div class="staff-auth pull-right">
                    <!-- <div class="auth-logo pull-left"><img src="assets/images/volunteer_auth.png" alt=""></div>
          <ul class="auth-list pull-right">
            <li><a href="#">徐汇区法律服务志愿团</a></li>
            <li><a href="">闵行区法律服务志愿团</a></li>
            <li><a href="">上海市人民调解委员会法律服务志愿团</a></li>
          </ul> -->
                    <ul class="auth-list clearfix">
                      <li>
                        <div class="auth-text">咨询人次</div>
                        <div class="circle">27</div>
                      </li>

                      <li>
                        <div class="auth-text">满意率</div>
                        <div class="circle" style="font-size: 16px">
                          100.00%
                        </div>
                      </li>
                      <li>
                        <div class="auth-text">公益积分</div>
                        <div class="circle">−</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="staff-info">
              <div class="tab">
                <ul class="tab-head clearfix" id="tab-affix">
                  <li><a href="#info01" class="">执业信息</a></li>
                  <li><a href="#info02" class="">专业能力</a></li>
                  <li><a href="#info03">服务业绩</a></li>
                  <li><a href="#info04" class="">服务记录</a></li>
                  <li><a href="#info05" class="active">信用记录</a></li>
                </ul>
                <div class="tab-content">
                  <div class="tab-section" id="info01">
                    <h4 class="head">执业信息</h4>
                    <div class="body">
                      <ul class="info-list clearfix">
                        <li><label class="name">性别：</label>男</li>
                        <li><label class="name">年龄：</label>47</li>
                        <li><label class="name">民族：</label>汉族</li>
                        <li>
                          <label class="name">政治面貌：</label>群众
                          <small
                            title="该信息由信用主体自行填写"
                            class="tab-section-prompt"
                          >
                            <i class="iconfont icon-mind"></i>
                          </small>
                        </li>
                        <li>
                          <label class="name">学历：</label>大学本科毕业
                          <small
                            title="该信息由信用主体自行填写"
                            class="tab-section-prompt"
                          >
                            <i class="iconfont icon-mind"></i>
                          </small>
                        </li>
                        <li>
                          <label class="name">执业机构：</label
                          >上海浩锦律师事务所
                        </li>
                        <li><label class="name">执业类别：</label>专职</li>
                        <li><label class="name">执业状态：</label>正常</li>
                        <li>
                          <label class="name">执业证号：</label
                          >13101200910651500
                        </li>
                        <li>
                          <label class="name">主管机关：</label>宝山区司法局
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="tab-section pr200" id="info02">
                    <h4 class="head">专业能力</h4>
                    <small class="tab-section-prompt">
                      <i class="iconfont icon-mind"></i>
                      以下数据来源于openlaw裁判文书网
                    </small>
                    <div class="body">
                      <div class="tab-section-title">案件类别</div>
                      <table class="table table-bordered" border="0">
                        <thead class="table-head">
                          <tr>
                            <th>案例统计图</th>
                            <th>序号</th>
                            <th>案件标题</th>
                            <th>案件类型</th>
                            <th>详情</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td rowspan="8">
                              <Proechart  divid='main1'></Proechart>
                            </td>
                            <td>1</td>
                            <td>盛某某、张某某诈骗一审刑事判决书</td>
                            <td>民事</td>
                            <td>
                              <a
                                class="table-link"
                                target="blank"
                                href="http://openlaw.cn/judgement/550734e7b1344c0ea44a75e3829bf05a"
                                >查看</a
                              >
                            </td>
                          </tr>

                          <tr>
                            <td>2</td>
                            <td>
                              王汝芳、何松娟与武道军房屋租赁合同纠纷一审民事判决
                            </td>
                            <td>民事</td>
                            <td>
                              <a
                                class="table-link"
                                target="blank"
                                href="http://openlaw.cn/judgement/426d50bb936b45c8945a52bd253a23e3"
                                >查看</a
                              >
                            </td>
                          </tr>

                          <tr>
                            <td>3</td>
                            <td>
                              上海中原物业顾问有限公司与邵思青居间合同纠纷一审民
                            </td>
                            <td>民事</td>
                            <td>
                              <a
                                class="table-link"
                                target="blank"
                                href="http://openlaw.cn/judgement/6f805cd1098044be98829d9063a47379"
                                >查看</a
                              >
                            </td>
                          </tr>

                          <tr>
                            <td>4</td>
                            <td>
                              上海静动平衡机制造有限公司与广西正弘聚机械设备有限
                            </td>
                            <td>民事</td>
                            <td>
                              <a
                                class="table-link"
                                target="blank"
                                href="http://openlaw.cn/judgement/b3a76c9a84714a5f8f9d7b9b40567831"
                                >查看</a
                              >
                            </td>
                          </tr>

                          <tr>
                            <td>5</td>
                            <td>
                              邵思青与上海奥晟体育管理有限公司、唐怡文等借款合同
                            </td>
                            <td>民事</td>
                            <td>
                              <a
                                class="table-link"
                                target="blank"
                                href="http://openlaw.cn/judgement/717277a1498d46e39442e96a3e6c1b97"
                                >查看</a
                              >
                            </td>
                          </tr>

                          <tr>
                            <td>6</td>
                            <td>
                              高善明诉上海市浦东新区北蔡镇杜文彬家具经营部买卖合
                            </td>
                            <td>民事</td>
                            <td>
                              <a
                                class="table-link"
                                target="blank"
                                href="http://openlaw.cn/judgement/b21d86077f374413b78290a694651f33"
                                >查看</a
                              >
                            </td>
                          </tr>

                          <tr>
                            <td>7</td>
                            <td>王宏根与王志勇抵押权纠纷一审民事判决书</td>
                            <td>民事</td>
                            <td>
                              <a
                                class="table-link"
                                target="blank"
                                href="http://openlaw.cn/judgement/81aecaeb349b43228d9547c82dff9f46"
                                >查看</a
                              >
                            </td>
                          </tr>

                          <tr>
                            <td>8</td>
                            <td>
                              刁德义与上海本盈土木工程有限公司建设工程分包合同纠
                            </td>
                            <td>民事</td>
                            <td>
                              <a
                                class="table-link"
                                target="blank"
                                href="http://openlaw.cn/judgement/2e4ecf4b9b294ba0b67e705768d51d77"
                                >查看</a
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="clearfix">
                        <div class="pro-left pull-left">
                          <div class="tab-section-title">
                            代理案件的审理法院层级
                          </div>
                           <Proechart  divid='main12'></Proechart>
                        </div>
                        <div class="pro-right pull-right">
                          <div class="tab-section-title">
                            代理案件的审理法院
                          </div>
                          <Proeline  divid='main13'></Proeline>
                        </div>
                      </div>
                      <div class="tab-section-title">
                        周向军律师共处理过52个法院案列
                      </div>
                      <table class="table table-bordered" border="0">
                        <thead class="table-head">
                          <tr>
                            <th>案例统计图</th>
                            <th>案例类型</th>
                            <th>案例件数</th>
                            <th>完全支持</th>
                            <th>部分支持</th>
                            <th>不支持</th>
                            <th>撤销</th>
                            <th>其他</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td width="266" rowspan="11">
                              <Proechart  divid='main4'></Proechart>
                            </td>

                            <td>赡养费纠纷</td>
                            <td>4</td>
                            <td>0</td>
                            <td>4</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                          </tr>

                          <tr>
                            <td>房屋买卖合同纠纷</td>
                            <td>2</td>
                            <td>0</td>
                            <td>2</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                          </tr>

                          <tr>
                            <td>故意伤害</td>
                            <td>1</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>1</td>
                          </tr>

                          <tr>
                            <td>所有权确认纠纷</td>
                            <td>1</td>
                            <td>1</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                          </tr>

                          <tr>
                            <td>借款合同纠纷</td>
                            <td>2</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>2</td>
                          </tr>

                          <tr>
                            <td>股权转让纠纷</td>
                            <td>1</td>
                            <td>1</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                          </tr>

                          <tr>
                            <td>土地租赁合同纠纷</td>
                            <td>2</td>
                            <td>1</td>
                            <td>0</td>
                            <td>1</td>
                            <td>0</td>
                            <td>0</td>
                          </tr>

                          <tr>
                            <td>离婚纠纷</td>
                            <td>5</td>
                            <td>0</td>
                            <td>0</td>
                            <td>4</td>
                            <td>1</td>
                            <td>0</td>
                          </tr>

                          <tr>
                            <td>房屋租赁合同纠纷</td>
                            <td>12</td>
                            <td>1</td>
                            <td>8</td>
                            <td>3</td>
                            <td>0</td>
                            <td>0</td>
                          </tr>

                          <tr>
                            <td>机动车交通事故责任纠纷</td>
                            <td>4</td>
                            <td>2</td>
                            <td>2</td>
                            <td>0</td>
                            <td>0</td>
                            <td>0</td>
                          </tr>
                          <tr>
                            <td>总计</td>
                            <td>52</td>
                            <td>6</td>
                            <td>16</td>
                            <td>8</td>
                            <td>1</td>
                            <td>3</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                   <div class="tab-section pr200" id="info03">
              <h4 class="head">服务业绩</h4>
              <div class="body">
                <div class="tab-section-title">周向军律师共处理过52个法院案列</div>
                <table class="table table-bordered" border="0">
                  <thead class="table-head">
                    <tr>
                      <th>案例统计图</th>
                      <th>案例类型</th>
                      <th>案例件数</th>
                      <th>完全支持</th>
                      <th>部分支持</th>
                      <th>不支持</th>
                      <th>撤销</th>
                      <th>其他</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                   <td width="266" rowspan="11">
                     <Proechart  divid='main2'></Proechart>
              </td>
                          <td>赡养费纠纷</td>
                          <td>4</td>
                          <td>0</td>
                          <td>4</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                        </tr>
                    <tr>
                          <td>房屋买卖合同纠纷</td>
                          <td>2</td>
                          <td>0</td>
                          <td>2</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                        </tr>
                      <tr>
                          <td>故意伤害</td>
                          <td>1</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>1</td>
                        </tr>
                      <tr>
                          <td>所有权确认纠纷</td>
                          <td>1</td>
                          <td>1</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                        </tr>
                      <tr>
                          <td>借款合同纠纷</td>
                          <td>2</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>2</td>
                        </tr>
                      <tr>
                          <td>股权转让纠纷</td>
                          <td>1</td>
                          <td>1</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                        </tr>
                      <tr>
                          <td>土地租赁合同纠纷</td>
                          <td>2</td>
                          <td>1</td>
                          <td>0</td>
                          <td>1</td>
                          <td>0</td>
                          <td>0</td>
                        </tr>
                      <tr>
                          <td>离婚纠纷</td>
                          <td>5</td>
                          <td>0</td>
                          <td>0</td>
                          <td>4</td>
                          <td>1</td>
                          <td>0</td>
                        </tr>
                      <tr>
                          <td>房屋租赁合同纠纷</td>
                          <td>12</td>
                          <td>1</td>
                          <td>8</td>
                          <td>3</td>
                          <td>0</td>
                          <td>0</td>
                        </tr>
                      <tr>
                          <td>机动车交通事故责任纠纷</td>
                          <td>4</td>
                          <td>2</td>
                          <td>2</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                        </tr>
                        <tr>
                          <td>总计</td>
                          <td>52</td>
                          <td>6</td>
                            <td>16</td>
                          <td>8</td>
                  <td>1</td>
                  <td>3</td>
                        </tr>
                  </tbody>
                </table>
              </div>
  </div><div class="tab-section pr200" id="info04">
                    <h4 class="head">服务记录</h4>
                    <div class="service-length clearfix">
                      <div class="service-box pull-left clearfix">
                        <div class="service-title pull-left">当年服务时长</div>
                        <div class="service-time pull-right">−</div>
                      </div>
                      <div class="service-box pull-right clearfix">
                        <div class="service-title pull-left">累计服务时长</div>
                        <div class="service-time pull-right">11小时</div>
                      </div>
                    </div>
                    <div class="body">
                      <table
                        class="table table-striped table-bordered"
                        border="0"
                      >
                        <thead>
                          <tr>
                            <th>类型</th>
                            <th>咨询人</th>
                            <th>时间</th>
                            <th>状态</th>
                            <th>评价</th>
                            <th>二级评价</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>热线法律咨询</td>
                            <td>咨**</td>
                            <td>2019-01-31 12:48:20</td>
                            <td>已办结</td>
                            <td>满意</td>
                            <td>满意</td>
                          </tr>

                          <tr>
                            <td>热线法律咨询</td>
                            <td>王**</td>
                            <td>2019-01-31 11:39:26</td>
                            <td>已办结</td>
                            <td>满意</td>
                            <td>满意</td>
                          </tr>

                          <tr>
                            <td>热线法律咨询</td>
                            <td>咨**</td>
                            <td>2018-11-22 14:45:18</td>
                            <td>已办结</td>
                            <td>满意</td>
                            <td>满意</td>
                          </tr>

                          <tr>
                            <td>热线法律咨询</td>
                            <td>咨**</td>
                            <td>2018-11-22 13:04:24</td>
                            <td>已办结</td>
                            <td>满意</td>
                            <td>满意</td>
                          </tr>

                          <tr>
                            <td>热线法律咨询</td>
                            <td>咨**</td>
                            <td>2018-11-22 12:23:02</td>
                            <td>已办结</td>
                            <td>满意</td>
                            <td>满意</td>
                          </tr>

                          <tr>
                            <td>热线法律咨询</td>
                            <td>咨**</td>
                            <td>2018-11-22 11:37:53</td>
                            <td>已办结</td>
                            <td>满意</td>
                            <td>满意</td>
                          </tr>

                          <tr>
                            <td>热线法律咨询</td>
                            <td>咨**</td>
                            <td>2018-11-22 10:30:33</td>
                            <td>已办结</td>
                            <td>满意</td>
                            <td>满意</td>
                          </tr>

                          <tr>
                            <td>热线法律咨询</td>
                            <td>咨**</td>
                            <td>2018-10-16 14:13:29</td>
                            <td>已办结</td>
                            <td>满意</td>
                            <td>满意</td>
                          </tr>

                          <tr>
                            <td>热线法律咨询</td>
                            <td>咨**</td>
                            <td>2018-10-16 11:59:17</td>
                            <td>已办结</td>
                            <td>满意</td>
                            <td>满意</td>
                          </tr>

                          <tr>
                            <td>热线法律咨询</td>
                            <td>咨**</td>
                            <td>2018-10-16 11:30:46</td>
                            <td>已办结</td>
                            <td>满意</td>
                            <td>满意</td>
                          </tr>
                        </tbody>
                      </table>

                      <a
                        href="./servicerecord-list.jsp?id=7a0a7f9700644c3bb1a57c8c4ba8f78e"
                        class="team-for-more"
                        >更多</a
                      >
                    </div>
                  </div>
                  <div class="tab-section pr200" id="info05">
                    <h4 class="head">信用记录</h4>
                    <div class="body">
                      <div class="tab-section-title">行政许可</div>
                      <table
                        class="table table-striped table-bordered"
                        border="0"
                      >
                        <thead>
                          <tr>
                            <th>序号</th>
                            <th>许可文号</th>
                            <th>许可内容</th>
                            <th>许可日期</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="4">暂无</td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="tab-section-title">考核信息</div>
                      <table
                        class="table table-striped table-bordered"
                        border="0"
                      >
                        <thead>
                          <tr>
                            <th>考核年度</th>
                            <th>考核结果</th>
                            <!--  <th>考核单位</th> -->
                            <th>考核时间</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>2010年度</td>

                            <td>称职</td>
                            <td>2011-04-16</td>
                          </tr>

                          <tr>
                            <td>2011年度</td>

                            <td>称职</td>
                            <td>2012-04-11</td>
                          </tr>

                          <tr>
                            <td>2012年度</td>

                            <td>称职</td>
                            <td>2013-04-12</td>
                          </tr>

                          <tr>
                            <td>2013年度</td>

                            <td>称职</td>
                            <td>2014-04-15</td>
                          </tr>

                          <tr>
                            <td>2014年度</td>

                            <td>称职</td>
                            <td>2015-04-13</td>
                          </tr>

                          <tr>
                            <td>2015年度</td>

                            <td>称职</td>
                            <td>2016-04-12</td>
                          </tr>

                          <tr>
                            <td>2016年度</td>

                            <td>称职</td>
                            <td>2017-04-09</td>
                          </tr>

                          <tr>
                            <td>2017年度</td>

                            <td>称职</td>
                            <td>2018-04-10</td>
                          </tr>

                          <tr>
                            <td>2018年度</td>

                            <td>称职</td>
                            <td>2019-05-21</td>
                          </tr>

                          <tr>
                            <td>2019年度</td>

                            <td>称职</td>
                            <td>2020-04-21</td>
                          </tr>
                        </tbody>
                      </table>

                      <div class="tab-section-title">表彰信息</div>
                      <table
                        class="table table-striped table-bordered"
                        border="0"
                      >
                        <thead>
                          <tr>
                            <th>信息类型</th>
                            <th>信息内容</th>
                            <th>发布时间</th>
                            <th>发布单位</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="4">暂无</td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="tab-section-title">失信信息</div>
                      <table
                        class="table table-striped table-bordered"
                        border="0"
                      >
                        <thead>
                          <tr>
                            <th>信息类型</th>
                            <th>信息内容</th>
                            <th>发布时间</th>
                            <th>发布单位</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="4">暂无</td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="tab-section-title">其他信息</div>
                      <table
                        class="table table-striped table-bordered"
                        border="0"
                      >
                        <thead>
                          <tr>
                            <th>信息类型</th>
                            <th>信息内容</th>
                            <th>发布时间</th>
                            <th>发布单位</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="4">暂无</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Page>
</template>
<script>
import Vue from 'vue'
import { Anchor } from 'ant-design-vue'
import Proechart from '../../components/base/Proechart.vue'
import Proeline from '../../components/base/Proeline.vue'
Vue.component(Anchor.name, Anchor)

export default {
  name: 'Peo',
  components: {
    Proechart,
    Proeline
  }
}
</script>
<style scoped src="../../assets/democss/peo.css"></style>
<style scoped src="../../assets/css/orgdetails.css"></style>
